import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@box-env/environment';
import { APIError, APIResponse, Coupon } from '@box-types';
import dayjs from 'dayjs';
import { Observable, Subject, Subscription, catchError, map, take, throwError, timer } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SentryService } from './sentry.service';
import { UserService } from './user.service';
import { isPrerenderBrowser } from '@box/utils';
import { LanguageService } from './language.service';

export type ExploreCouponData = { coupon: Coupon; userSegments: string[] };

@Injectable({ providedIn: 'root' })
export class ExplorePromoCampaignService implements OnDestroy {
  private readonly BOX_API = environment.application.API_URL;
  private coupon: Coupon;
  private exploreSubscription: Subscription;
  private readonly remainingTimeSource = new Subject<number>();
  public readonly remainingTime$ = this.remainingTimeSource.asObservable();

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private sentryService: SentryService,
    private languageService: LanguageService
  ) {}

  ngOnDestroy(): void {
    this.exploreSubscription?.unsubscribe();
  }

  public getCoupon(): Coupon {
    return this.coupon;
  }

  public clearCoupon(): void {
    this.coupon = undefined;
  }

  public fetchExploreCouponData(): Observable<ExploreCouponData> {
    return this.http.get(`${this.BOX_API}/promo-coupons/explore`).pipe(
      map((response: APIResponse<ExploreCouponData>) => response.payload),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Promo Campaigns',
          domainDetails: 'Explore Promo Campaigns Service - Fetch Explore Coupon Data',
          severity: 'warning'
        });
        return throwError(() => error);
      })
    );
  }

  public clearTimer(): void {
    this.exploreSubscription?.unsubscribe();
  }

  public initialize(coupon: Coupon): void {
    if (isPrerenderBrowser(window)) return;
    if (!coupon?.expirationDate) return;
    this.coupon = coupon;
    const currentDate = dayjs();
    const couponExpirationDate = dayjs(coupon.expirationDate);
    const startingValue = couponExpirationDate.diff(currentDate, 'second');
    if (startingValue <= 0) return;
    const preDelayMilliseconds = 1000 - currentDate.millisecond();
    this.exploreSubscription = timer(preDelayMilliseconds, 1000)
      .pipe(
        map((timerValue) => startingValue - timerValue),
        take(startingValue + 1)
      )
      .subscribe({
        next: (remainingValue) => this.remainingTimeSource.next(remainingValue),
        complete: () => {
          this.fetchExploreCouponData().subscribe((exploreData) => {
            const { coupon, userSegments } = exploreData;
            this.coupon = coupon;
            this.userService.setUser({ ...this.userService.getUser(), segments: userSegments });
            this.remainingTimeSource.complete();
            const snackConfig: MatSnackBarConfig = { duration: 6000 };
            const snackText = this.languageService.getTextByKey('30_percent_discount_has_expired');
            const actionText = this.languageService.getTextByKey('ok_');
            this.snackBar.open(snackText, actionText, snackConfig);
          });
        }
      });
  }
}
