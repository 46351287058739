import { Component, Inject, Renderer2, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Address } from '@box-types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '../box-dialog-wrapper/box-dialog-wrapper.component';
import { AddressesService } from '@box-core/services';
import { areAddressesEqual } from '@box/utils';
import { AddressSelectDialogData, AddressSelectDialogResponse } from './address-select-dialog.types';

@Component({
  selector: 'address-select-dialog.',
  templateUrl: './address-select-dialog.component.html',
  styleUrls: ['./address-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSelectDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public addresses: Address[] = [];
  public currentAddress: Address;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<AddressSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressSelectDialogData,
    private addressesService: AddressesService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.addresses = this.data.addresses;
    this.currentAddress = this.addressesService.getAddress();
  }

  public closeDialog(data?: AddressSelectDialogResponse): void {
    this.dialogRef.close(data);
  }

  public onAddressSelect(address: Address): void {
    if (this.areAddressesEqual(address, this.currentAddress)) return this.closeDialog({ set: false });
    this.currentAddress = address;
    this.addressesService.setAddress(address);
    this.closeDialog({ set: true });
  }

  public addAddress(): void {
    this.closeDialog({ create: true });
  }

  public areAddressesEqual(addressA: Address, addressB: Address): boolean {
    return areAddressesEqual(addressA, addressB);
  }
}
