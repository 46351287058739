<div *ngIf="showTopDisabledBar" class="coupon-disabled-background">
  <span class="coupon-disabled-text ods-typography-microcopy-regular">{{ disabledText }}</span>
</div>

<div
  class="coupon-container ticket"
  [ngClass]="{
    'ticket--active coupon-active': active,
    'coupon-unavailable-container': !available || showTopDisabledBar
  }"
>
  <div class="coupon-stub">
    <span
      [style.color]="primaryTextColor"
      [style.text-shadow]="'1px 1px ' + primaryTextShadowColor"
      [style.font-size]="primaryTextSize"
      [class.coupon-primary-text-disabled]="showTopDisabledBar"
      [class.coupon-primary-text-unavailable]="!available || couponDisabled"
      class="ods-typography-title-l coupon-primary-text"
      >{{ primaryText }}</span
    >
  </div>
  <div class="coupon-counterfoil">
    <div class="coupon-counterfoil-first-row">
      <span
        [style.color]="secondaryTextColor"
        class="ods-typography-microcopy-bold coupon-secondary-text"
        [class.coupon-secondary-text-unavailable]="!available || couponDisabled"
        >{{ secondaryText }}</span
      >
      <coupon-countdown *ngIf="showCountDown" [coupon]="coupon"></coupon-countdown>
      <div *ngIf="topRightText" class="coupon-unavailable-label">
        <span
          [class.coupon-counterfoil-text-disabled]="showTopDisabledBar"
          class="ods-typography-microcopy-bold coupon-counterfoil-text"
          >{{ topRightText }}</span
        >
      </div>
    </div>

    <span
      class="coupon-description ods-typography-body-s-regular"
      [class.coupon-description-disabled]="showTopDisabledBar"
      [class.truncate]="showCode"
      [class.coupon-description-checkout]="showCode"
      [innerHTML]="shortDescription"
    ></span>
    <span
      *ngIf="code && showCode"
      class="ods-typography-body-s-regular coupon-code"
      [class.coupon-code-disabled]="showTopDisabledBar"
      >{{ 'code_' | translate }} {{ code }}</span
    >
  </div>
</div>
