import { Injectable } from '@angular/core';
import { APIError, BOXSentryCaptureExceptionOptions } from '@box-types';
import { init, withScope, captureException, SeverityLevel, browserTracingIntegration } from '@sentry/angular';
import { environment } from '@box-env/environment';
import { isPrerenderBrowser, isBrowserHeadless } from '@box/utils';
import { Platform } from '@angular/cdk/platform';

@Injectable({ providedIn: 'root' })
export class SentryService {
  constructor(private platform: Platform) {}

  public init(): void {
    init({
      dsn: environment.application.SENTRY_DSN,
      environment: environment.code,
      release: `box-web@${String(environment.application.VERSION)}`,
      integrations: [browserTracingIntegration()],
      tracesSampleRate: 0.05,
      ignoreErrors: ['Error: Non-Error exception captured with keys: error, headers, message, name, ok']
    });
  }

  public captureException(error: Error | APIError, options?: BOXSentryCaptureExceptionOptions): void {
    if (this.isBotClient()) return;
    withScope(function (scope) {
      if (options?.domain) scope.setTag('box.domain', options.domain);
      if (options?.domainDetails) scope.setTag('box.domain.details', options.domainDetails);
      if (options?.severity) scope.setLevel(options.severity as SeverityLevel);
      captureException(error);
    });
  }

  private isBotClient(): boolean {
    return isPrerenderBrowser(window) || !this.platform.isBrowser || isBrowserHeadless();
  }
}
