import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

import { CoreService, SentryService } from '@box-core/services';
import { CoreItems, APIError } from '@box-types';

@Injectable({ providedIn: 'root' })
export class CoreResolver implements Resolve<CoreItems> {
  constructor(private coreService: CoreService, private sentryService: SentryService) {}

  resolve(): Observable<CoreItems> {
    return this.coreService.fetchItems().pipe(
      map((items) => this.coreService.filterEnabledItems(items)),
      tap((items) => this.coreService.setItems(items)),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Core',
          domainDetails: 'Core Resolver',
          severity: 'warning'
        });
        return throwError(() => error);
      })
    );
  }
}
