import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

function goToLogin(): void {
  const loginElement: HTMLElement = window.document.querySelector('#cosid_md_login');
  loginElement.click();
}

function sortSuggestionElements<T>(elements: (T & { points?: number; multiplier?: number })[]): T[] {
  if (!elements?.length || elements.length <= 1) return elements;
  const withMultiplier = elements
    .filter((element) => element?.multiplier > 0)
    .sort((a, b) => b.multiplier - a.multiplier);
  const withPoints = elements.filter((element) => element?.points > 0).sort((a, b) => b.points - a.points);
  const withoutMultiplierOrPoints = elements.filter((element) => !element?.multiplier && !element?.points);
  return [...withMultiplier, ...withPoints, ...withoutMultiplierOrPoints].filter(Boolean);
}

function hashString(input: string): string {
  if (!input?.length) return;
  let hash = 0;
  for (let i = 0, len = input.length; i < len; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
}

function getObjectKeysRecursively(obj: unknown, prefix = ''): string[] {
  if (!obj) return [];
  return Object.entries(obj).reduce((keysCollector: string[], [key, val]: [string, unknown]) => {
    const newKey = prefix ? `${prefix}[${key}]` : key;
    const updatedKeys = [...keysCollector, newKey];

    const valueType = Object.prototype.toString.call(val);
    const leafHasReachedEnd = valueType !== '[object Array]' && valueType !== '[object Object]';
    if (leafHasReachedEnd) return updatedKeys;

    const otherKeys = getObjectKeysRecursively(val, newKey);
    return [...updatedKeys, ...otherKeys];
  }, []);
}

function isBrowserHeadless(): boolean {
  if (isUserAgentHeadless()) return true;
  if (!window) return true;
  if (!window['RTCPeerConnection']) return true;
  if (navigator?.webdriver) return true;
  if (!navigator?.languages?.length) return true;
  return false;
}

function isUserAgentHeadless(): boolean {
  const userAgent = navigator?.userAgent;
  if (!userAgent) return false;
  const flags = ['bot', 'headless', 'phantomjs', 'puppeteer'];
  return flags.some((flag) => userAgent.toLowerCase().includes(flag));
}

export {
  goToLogin,
  sortSuggestionElements,
  hashString,
  getObjectKeysRecursively,
  isBrowserHeadless,
  isUserAgentHeadless
};
