import {
  Component,
  OnInit,
  Renderer2,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
  Input
} from '@angular/core';
import { CheckoutCouponsService, CheckoutStateService } from '@box-checkout/services';
import { Coupon } from '@box-types';
import { MatDialogRef } from '@angular/material/dialog';
import { BankPointsRedeemSlideDialogComponent } from '@box-checkout/components';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { CheckoutCouponsDialogResponse } from '@box-checkout/components/checkout-coupons-dialog/checkout-coupons-dialog.interface';
import { Subscription } from 'rxjs';
import { CouponsService, NotificationsService } from '@box-core/services';

@Component({
  selector: 'checkout-coupons-dialog',
  templateUrl: './checkout-coupons-dialog.component.html',
  styleUrls: ['./checkout-coupons-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutCouponsDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public coupons: Coupon[];
  public selectedCoupon: Coupon;

  private couponsSubscription: Subscription;
  private couponSubscription: Subscription;
  public startingPrice: number;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<BankPointsRedeemSlideDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private checkoutCouponsService: CheckoutCouponsService,
    private couponsService: CouponsService,
    private checkoutStateService: CheckoutStateService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.setCouponSubscription();
    this.setCouponsSubscription();
    this.changeDetectorRef.detectChanges();
    this.startingPrice = this.checkoutStateService.getCheckoutOrderPreview().cartStartingPrice;
  }

  ngOnDestroy(): void {
    this.couponSubscription?.unsubscribe();
    this.couponsSubscription?.unsubscribe();
  }

  private setCouponsSubscription(): void {
    this.couponsSubscription = this.checkoutCouponsService.coupons$.subscribe((coupons) => {
      this.coupons = coupons;
      this.startingPrice = this.checkoutStateService.getCheckoutOrderPreview().cartStartingPrice;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCouponSubscription(): void {
    this.couponSubscription = this.checkoutCouponsService.coupon$.subscribe((coupon) => {
      this.selectedCoupon = coupon;
      this.startingPrice = this.checkoutStateService.getCheckoutOrderPreview().cartStartingPrice;
      this.changeDetectorRef.detectChanges();
    });
  }

  public onCouponClick(coupon: Coupon): void {
    this.checkoutCouponsService.selectCoupon(coupon);
    this.closeDialog();
  }

  public onCouponAddClick(): void {
    this.closeDialog({ addCoupon: true } as CheckoutCouponsDialogResponse);
  }

  public closeDialog(data?: CheckoutCouponsDialogResponse): void {
    this.dialogRef.close(data);
  }

  public trackByCode(index: number, item: Coupon): string {
    return item.code;
  }

  public onCouponExpiration(coupon: Coupon): void {
    const filteredCoupons = this.coupons.filter((c) => c.code !== coupon.code);
    this.checkoutCouponsService.setCoupons(filteredCoupons);
    this.couponsService.showCouponExpirationNotification(coupon.code);
  }
}
