import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PromoCampaign, APIError } from '@box-types';
import { CampaignTimerService, PromoCampaignsService, SentryService } from '@box-core/services';
import { normalizePromoCampaign } from '@box/utils';

@Injectable({ providedIn: 'root' })
export class PromoCampaignsResolver implements Resolve<PromoCampaign[]> {
  constructor(
    private sentryService: SentryService,
    private campaignTimerService: CampaignTimerService,
    private promoCampaignsService: PromoCampaignsService
  ) {}

  resolve(): Observable<PromoCampaign[]> {
    return this.promoCampaignsService.fetchPromoCampaigns().pipe(
      map((promoCampaigns) => promoCampaigns.map((c) => normalizePromoCampaign(c))),
      tap((promoCampaigns) => {
        this.promoCampaignsService.setPromoCampaigns(promoCampaigns);
        this.campaignTimerService.initialize(promoCampaigns);
      }),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Core',
          domainDetails: 'Promo Campaigns Resolver',
          severity: 'warning'
        });
        return of([]);
      })
    );
  }
}
