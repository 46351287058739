<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="redeem_your_coupon" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="checkout-coupons-container">
      <coupon
        *ngFor="let coupon of coupons; trackBy: trackByCode"
        [active]="selectedCoupon?.code === coupon.code"
        [coupon]="coupon"
        [showCode]="true"
        (expired)="onCouponExpiration(coupon)"
        (click)="onCouponClick(coupon)"
        [startingPrice]="startingPrice"
      ></coupon>
    </div>
    <footer #dialogFooter class="box-dialog-footer checkout-coupons-dialog-footer">
      <button
        box-button
        size="small"
        theme="secondary"
        borders="none"
        class="checkout-add-coupon"
        (click)="onCouponAddClick()"
      >
        {{ 'insert_new_coupon' | translate }}
      </button>
    </footer>
  </main>
</div>
