import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Campaign, APIError } from '@box-types';
import { LoyaltyService, SentryService } from '@box-core/services';

@Injectable({ providedIn: 'root' })
export class LoyaltyResolver implements Resolve<Campaign[]> {
  constructor(private sentryService: SentryService, private loyaltyService: LoyaltyService) {}

  resolve(): Observable<Campaign[]> {
    return this.loyaltyService.getCampaign().pipe(
      tap((campaigns) => this.loyaltyService.setCampaigns(campaigns)),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Core',
          domainDetails: 'Loyalty Resolver',
          severity: 'warning'
        });
        return throwError(() => error);
      })
    );
  }
}
