import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import {
  DiscoverSearchOptions,
  DiscoverSearchResponse,
  Shop,
  SearchStatusType,
  APIError,
  APIResponse
} from '@box-types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AddressesService } from '@box-core/services/addresses.service';
import { ShopsService } from '@box-core/services/shops.service';
import { sortShopsBasedOnOperatingState, sortSearchShopsByScore } from '@box/utils';
import { SentryService } from './sentry.service';
import { ShopService } from '@box-core/services/shop.service';

@Injectable({ providedIn: 'root' })
export class HomeSearchService {
  private BOX_API: string = environment.application.API_URL;

  public searchStatus = new BehaviorSubject<SearchStatusType>('PENDING');
  public searchResults = new BehaviorSubject<Shop[]>(undefined);
  public searchTerm = new BehaviorSubject<string>(undefined);

  constructor(
    private http: HttpClient,
    private addressesService: AddressesService,
    private shopsService: ShopsService,
    private shopService: ShopService,
    private sentryService: SentryService
  ) {}

  public fetchSearchShops(searchTerm: string): Observable<Shop[]> {
    const address = this.addressesService.getAddress();
    const options: DiscoverSearchOptions = {
      term: searchTerm,
      businessVertical: 'food',
      address: address,
      limit: 4,
      sortOnType: 'shops'
    };
    const params: HttpParams = this.generateDiscoverSearchParams(options);
    return this.http.get(`${this.BOX_API}/items`, { params }).pipe(
      map((response: APIResponse<DiscoverSearchResponse>) => {
        const searchResults = response.payload.shops;
        const shops = this.shopsService.getShops();
        const decoratedShops = shops.map((shop) => this.shopService.shopPromoDecorator(shop));
        if (!searchResults?.length || !shops?.length) return [];

        // duplicate logic in discover-search.service.ts
        const shopSearchResultsSortedByScore = sortSearchShopsByScore(searchResults, 'shopScore');
        const shopsSortedByScore = shopSearchResultsSortedByScore.reduce<Shop[]>((shopsSortedByScore, searchResult) => {
          const shop = decoratedShops.find((shop) => shop._id === searchResult.shopId);
          if (!shop) return shopsSortedByScore;
          shopsSortedByScore.push(shop);
          return shopsSortedByScore;
        }, []);
        return sortShopsBasedOnOperatingState(shopsSortedByScore);
      }),
      catchError((error: Error | APIError) => {
        const isAPIError = (error as APIError)?.status;
        if (!isAPIError) {
          this.sentryService.captureException(error, {
            domain: 'Home',
            domainDetails: 'Home Search Service',
            severity: 'warning'
          });
        }
        return of([]);
      })
    );
  }

  private generateDiscoverSearchParams(options: DiscoverSearchOptions): HttpParams {
    return new HttpParams({
      fromObject: {
        term: options.term,
        businessVertical: options.businessVertical,
        latitude: String(options.address.latitude),
        longitude: String(options.address.longitude),
        limit: options.limit,
        sortOnType: options.sortOnType
      }
    });
  }

  public setSearchStatus(status: SearchStatusType): void {
    this.searchStatus.next(status);
  }

  public setSearchResults(searchResults: Shop[]): void {
    this.searchResults.next(searchResults);
  }

  public setSearchTerm(term: string): void {
    this.searchTerm.next(term);
  }
}
