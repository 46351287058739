import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@box-env/environment';
import { map } from 'rxjs/operators';
import { Review, CreateReviewOptions, APIResponse } from '@box-types';
import { pickBy } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ReviewsService {
  private BOX_API = environment.application.API_URL;

  constructor(private http: HttpClient) {}

  public fetchReviews(collectionType: number): Observable<Review[]> {
    const params = new HttpParams().set('collectionType', collectionType);
    return this.http
      .get(`${this.BOX_API}/ratings`, { params })
      .pipe(map((response: APIResponse<{ ratings: Review[] }>) => response.payload.ratings));
  }

  public createReview(options: CreateReviewOptions): Observable<Review> {
    const body = pickBy(options);
    return this.http
      .post(`${this.BOX_API}/ratings`, body)
      .pipe(map((response: APIResponse<Review>) => response.payload));
  }
}
