import { Address, User, MapCircle, MapPoint, GetTextByKeyType, AddressType } from '@box-types';
import { FormGroup } from '@angular/forms';
import { minBy } from 'lodash-es';
import { circleContainsPoint, getPointsDistance } from '../maps/maps.utils';

export {
  getAddressDescription,
  addressToString,
  getAddressType,
  addressToHeaderText,
  decorateAddressWithUser,
  generateGoogleMapsDirectionsUrl,
  areAddressesEqual,
  decorateAddressWithDescription,
  isAddressReadyForDelivery,
  getAddressIcon,
  isAddressReadyForView,
  generatePlacePredictionQuery,
  getClosestAddress,
  sortAddresses,
  normalizeAddress
};

const MAX_DISTANCE = 2000;

function addressToHeaderText(address: Address, translateFn: GetTextByKeyType): string {
  if (!address) return 'choose_address';
  const { type, city, region, postalCode } = address;
  return `${type ? `${translateFn(type)} - ` : ''}${(city || region) ?? ''} ${postalCode ?? ''}`;
}

function getAddressDescription(address: Address): string {
  if (!address) return;
  const { city, region, streetNo, street, postalCode } = address;
  const addressComponents: string[] = [];
  if (street) {
    const streetText = street + (streetNo ? ` ${streetNo}` : '');
    addressComponents.push(streetText);
  }
  const regionText = city ?? region; // this will be replaced when we fix the place to address inconsistency
  if (postalCode) addressComponents.push(`${regionText} ${postalCode}`);
  return addressComponents.join(', ');
}

function decorateAddressWithDescription(address: Address): Address {
  if (!address || address.description) return address;
  return { ...address, description: getAddressDescription(address) };
}

function addressToString(address: Address, translateFn: GetTextByKeyType): string {
  const { floor, nameAtBell } = address;
  const description = getAddressDescription(address);
  const addressComponents = [description];
  if (floor) addressComponents.push(translateFn('floor_text', { _FLOOR: floor }));
  if (nameAtBell) addressComponents.push(translateFn('name_at_bell_text', { _NAME_AT_BELL: nameAtBell }));
  return addressComponents.join(', ');
}

function getAddressType(addressType: string): AddressType {
  if (addressType === 'home_') return 'home';
  if (addressType === 'work_') return 'work';
  return 'other';
}

/* needed for cosmote id */
function decorateAddressWithUser(address: Address, user: User): Address {
  if (!address || !user) return;
  // potentially refactor
  if (!user) return address;
  return { ...address, firstName: user.firstName, lastName: user.lastName };
}

function generateGoogleMapsDirectionsUrl(destinationAddress: Address): string {
  if (!destinationAddress) return;
  const { latitude, longitude } = destinationAddress;
  if (!latitude || !longitude) return;
  return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
}

function areAddressesEqual(address1: Address, address2: Address): boolean {
  /* guest user addresses have no id.
   These are the properties that uniquely identify an address.
   The coordinates of an address are not a good uniqueness criteria,
   since the same address can have slightly different coordinates.
   There is some sort of "noise" introduced by google's api. */
  return (
    address1?.street === address2?.street &&
    address1?.streetNo === address2?.streetNo &&
    address1?.floor === address2?.floor &&
    address1?.nameAtBell === address2?.nameAtBell &&
    address1?.postalCode === address2?.postalCode &&
    address1?.type === address2?.type
  );
}

function isAddressReadyForView(address: Address): boolean {
  return (
    Boolean(address) &&
    address.latitude > 0 &&
    address.longitude > 0 &&
    Boolean(address.city) &&
    address.city !== 'none' &&
    Boolean(address.street)
  );
}

function isAddressReadyForDelivery(address: Address): boolean {
  // streetNo is not required, since some provincial areas, have a street only address
  return isAddressReadyForView(address) && Boolean(address.floor) && Boolean(address.nameAtBell);
}

function generatePlacePredictionQuery(addressForm: FormGroup): string {
  if (!addressForm?.controls) return;
  const street = (addressForm.controls.street?.value as string) ?? ''; // route
  const streetNo = (addressForm.controls.streetNo?.value as string) ?? ''; // street_number
  const city = (addressForm.controls.city?.value as string) ?? ''; // locality
  return [city, street, streetNo].join(' ');
}

function getAddressIcon(address: Address): string {
  const path = '/assets/images/addresses/';
  if (!address) return path + 'address-type_other.svg';
  const addressType = getAddressType(address.type);
  switch (addressType) {
    case 'home':
      return path + 'address-type_home.svg';
    case 'work':
      return path + 'address-type_work.svg';
    default:
      return path + 'address-type_other.svg';
  }
}

function getClosestAddress(addresses: Address[], userPoint: MapPoint): Address {
  if (!addresses?.length) return;
  const area: MapCircle = { lat: userPoint?.lat, lng: userPoint?.lng, radius: MAX_DISTANCE };
  return minBy(addresses, (address) => {
    const addressPoint: MapPoint = { lat: address?.latitude, lng: address?.longitude };
    const addressInsideArea = circleContainsPoint(area, addressPoint);
    const addressDistance = getPointsDistance(addressPoint, userPoint);
    if (addressInsideArea) return addressDistance;
  });
}

function sortAddresses(addresses: Address[]): Address[] {
  if (!addresses?.length) return [];
  const homeAddresses: Address[] = addresses.filter((a) => getAddressType(a.type) === 'home');
  const workAddresses: Address[] = addresses.filter((a) => getAddressType(a.type) === 'work');
  const otherAddresses: Address[] = addresses.filter((a) => getAddressType(a.type) === 'other');
  return [...homeAddresses, ...workAddresses, ...otherAddresses];
}

function normalizeAddress(address: Address): Address {
  if (!address) return;
  if (address.type === 'Σπίτι' || address.type === 'Home') return { ...address, type: 'home_' };
  if (address.type === 'Εργασία' || address.type === 'Work') return { ...address, type: 'work_' };
  return address;
}
