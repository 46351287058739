import { currencyFormat, getPercentageDiscount, CurrencyFormatOptions } from '../core';
import {
  Coupon,
  CouponBenefitType,
  GetTextByKeyType,
  CurrencyCode,
  PromoCampaign,
  PromoVisualOptions
} from '@box-types';
import { getPromoColor, getPromoText } from '../promo-campaigns/promo-campaigns.utils';
import dayjs from 'dayjs';
import {
  getCouponLogo,
  getCouponPrimaryTextShadowColorDark,
  getCouponPrimaryTextShadowColorLight
} from './coupon-content.utils';
import { isEqual } from 'lodash-es';

function isCouponLoyalty(coupon: Coupon): boolean {
  return coupon.benefitType === 'LOYALTY_POINTS';
}

function isCouponPersonalised(coupon: Coupon): boolean {
  return coupon.benefitType === 'PERSONALISED_CAMPAIGN';
}

function isCouponDiscount(coupon: Coupon): boolean {
  if (!coupon.benefitType) return false;
  return ['DISCOUNT_FIXED', 'DISCOUNT_PERCENTAGE'].includes(coupon.benefitType);
}

function discountMeetsCouponRequirements(coupon: Coupon, discount: number, value: number): boolean {
  if (!coupon.minimumPrice) return true;
  const valueAfterDiscount = value - discount;
  return coupon.minimumPrice <= valueAfterDiscount;
}

function isCouponKnown(coupon: Coupon): boolean {
  if (!coupon) return false;
  const knownTypes: CouponBenefitType[] = [
    'LOYALTY_POINTS',
    'PERSONALISED_CAMPAIGN',
    'DISCOUNT_PERCENTAGE',
    'DISCOUNT_FIXED'
  ];
  return knownTypes.includes(coupon.benefitType);
}

function isCouponUsed(coupon: Coupon): boolean {
  const { available, redemptionDate } = coupon;
  if (available) return false;
  return Boolean(redemptionDate);
}

function isCouponExpired(coupon: Coupon): boolean {
  const { available, expirationDate } = coupon;
  if (available) return false;
  return Boolean(expirationDate);
}

function isCouponDisabled(coupon: Coupon): boolean {
  return Boolean(coupon.disabledText);
}

function isCouponValid(coupon: Coupon): boolean {
  if (!coupon) return false;
  const { benefitType, loyaltyPoints, percentage, amount, info } = coupon;
  const { primaryText, secondaryText } = info ?? {};
  if (benefitType === 'DISCOUNT_FIXED' && !amount) return false;
  if (benefitType === 'DISCOUNT_PERCENTAGE' && !percentage) return false;
  if (benefitType === 'LOYALTY_POINTS' && !loyaltyPoints) return false;
  const missingText = !primaryText || !secondaryText;
  if (benefitType === 'PERSONALISED_CAMPAIGN' && missingText) return false;
  return true;
}

function isCouponDummy(coupon: Coupon): boolean {
  if (!coupon) return false;
  const { dummy, triggerPromoInitiation } = coupon;
  if (!dummy) return false;
  return Boolean(triggerPromoInitiation?.name);
}

function isCouponSelectable(coupon: Coupon): boolean {
  return !isCouponUsed(coupon) && !isCouponDisabled(coupon) && !isCouponExpired(coupon) && isCouponKnown(coupon);
}

function isOrderCoupon(coupon: Coupon): boolean {
  return isCouponDiscount(coupon) || isCouponPersonalised(coupon);
}

function couponHasDefaultShopFiltering(coupon: Coupon): boolean {
  const filteringOptions = coupon?.info?.shopsFiltering;
  const defaultShopsFiltering = {
    shops: [],
    displayDaasShops: false,
    cuisines: [],
    primaryOrSecondaryCuisines: [],
    chains: [],
    shopCampaigns: []
  };
  return isEqual(filteringOptions, defaultShopsFiltering) || !filteringOptions;
}

function filterWalletCoupons(coupons: Coupon[]): Coupon[] {
  if (!coupons?.length) return [];
  return coupons.filter((coupon) => {
    // we check if coupon has the default shop filtering, as well as if it has eligible shops because
    // if the user has no address and therefore no shops, we still want to show the default coupons
    return isCouponLoyalty(coupon) || coupon?.eligibleShops?.length > 0 || couponHasDefaultShopFiltering(coupon);
  });
}

function isCouponRedeemamble(coupon: Coupon): boolean {
  return isCouponLoyalty(coupon);
}

function getCouponDiscount(coupon: Coupon, amount: number): number {
  if (!coupon) return 0;
  if (!isOrderCoupon(coupon)) return 0;
  if (coupon.amount) return coupon.amount; // fixed discount
  if (!amount || amount <= 0 || !coupon.percentage || coupon.percentage <= 0) return 0;
  const discount = Math.floor(getPercentageDiscount(amount, coupon.percentage));
  if (coupon.maximumDiscount) return Math.min(discount, coupon.maximumDiscount);
  return discount;
}

function getDiscountWarningMessage(
  coupon: Coupon,
  discount: number,
  value: number,
  translateFn: GetTextByKeyType,
  currencyCode
): string {
  const formatOptions: CurrencyFormatOptions = { symbolSpace: true, minimumFractionDigits: 2, currencyCode };
  const valueAfterDiscount = value - discount;
  const couponDiscount = getCouponDiscount(coupon, valueAfterDiscount);
  const couponDiscountText = currencyFormat(couponDiscount, formatOptions);
  const minimumPriceText = currencyFormat(coupon.minimumPrice, formatOptions);
  return translateFn('to_get_discount_your_cart_must_be_at_least', {
    _COUPON_DISCOUNT: couponDiscountText,
    _MINIMUM_PRICE: minimumPriceText
  });
}

function getDiscountResultMessage(
  coupon: Coupon,
  value: number,
  translateFn: GetTextByKeyType,
  currencyCode: CurrencyCode
): string {
  const formatOptions: CurrencyFormatOptions = { symbolSpace: true, minimumFractionDigits: 2, currencyCode };
  const couponDiscount = getCouponDiscount(coupon, value);
  const valueAfterDiscount = value - couponDiscount;
  const cartPriceText = currencyFormat(valueAfterDiscount, formatOptions);
  return translateFn('with_the_discount_coupon_the_new_price_of_your_cart_is', {
    _CART_PRICE: cartPriceText
  });
}

function getCouponBenefitText(
  coupon: Coupon,
  startingPrice: number,
  translateFn: GetTextByKeyType,
  currencyCode: CurrencyCode
): string {
  if (!coupon) return '';
  if (coupon.loyaltyPoints) return ` ${coupon.loyaltyPoints} ${translateFn('the_points')}`;
  const couponDiscount = getCouponDiscount(coupon, startingPrice);
  const discount = Math.min(couponDiscount, startingPrice);
  if (discount <= 0) return '';
  return ` ${currencyFormat(discount, {
    symbolSpace: false,
    currencyCode,
    minimumFractionDigits: getCouponMinimumFractionDigits(discount)
  })}`;
}

function getCouponMinimumFractionDigits(cents: number) {
  // Determine minimumFractionDigits dynamically
  const hasFractionalPart = cents % 100 !== 0;
  return hasFractionalPart ? 2 : 0;
}

function getPizzaFanCouponDescription(coupon: Coupon, translateFn: GetTextByKeyType): string {
  if (coupon?.code)
    return translateFn('redeem_the_code_and_get_the_pizza_fan_offer', {
      _COUPON_CODE: coupon.code
    });
  return translateFn('redeem_dfy_code_and_get_the_pizza_fan_offer');
}

function isCouponRelatedToSynergy(coupon: Coupon, synergy: string): boolean {
  if (!coupon.synergy) return false;
  return coupon.synergy === synergy;
}

function getSpecificPromoCampaignCoupons(coupons: Coupon[], promoCampaigns: PromoCampaign[]): Coupon[] {
  if (!coupons?.length) return [];
  if (!promoCampaigns?.length) return [];

  return coupons.reduce((coupons: Coupon[], coupon) => {
    const couponSpecificPromoCampaign = promoCampaigns.some((pc) => {
      if (pc?.overriddenByCouponSynergies) return pc.overriddenByCouponSynergies.includes(coupon.synergy);
    });
    if (couponSpecificPromoCampaign) {
      coupons.push(coupon);
    }
    return coupons;
  }, [] as Coupon[]);
}

function getCouponsSynergies(coupons: Coupon[]): string[] {
  if (!coupons?.length) return [];

  return coupons.reduce((synergies: string[], coupon) => {
    if (coupon.synergy) synergies.push(coupon.synergy);
    return synergies;
  }, [] as string[]);
}

function getBestCouponByPriority(coupons: Coupon[]): Coupon {
  if (!coupons?.length) return;
  const bestCouponByPriority = coupons.sort((a, b) => b.info?.priority - a.info?.priority);
  if (!bestCouponByPriority?.length) return;
  return bestCouponByPriority[0];
}

function isCouponRelatedToOverriddenSynergy(promoCampaign: PromoCampaign, coupon: Coupon): boolean {
  if (!promoCampaign?.overriddenByCouponSynergies?.length) return false;
  if (!coupon?.synergy) return false;
  const synergy = promoCampaign.overriddenByCouponSynergies.find((synergy) => synergy === coupon.synergy);
  if (!synergy) return false;
  return isCouponRelatedToSynergy(coupon, synergy);
}

function couponSpecificPromoOptions(coupons: Coupon[], textKey: string): PromoVisualOptions {
  if (!coupons?.length) return;
  const coupon = getBestCouponByPriority(coupons);
  const couponTexts = coupon.info?.promoTexts;
  if (!couponTexts) return;
  const couponColors = coupon.info?.promoColors;
  return {
    text: getPromoText(couponTexts, textKey),
    colors: {
      foreground: getPromoColor(couponColors, 'foregroundTag'),
      background: getPromoColor(couponColors, 'backgroundTag'),
      triangle: getPromoColor(couponColors, 'triangleTag'),
      sideTag: getPromoColor(couponColors, 'sideTag')
    }
  };
}

function couponExpiresWithinDaysRange(coupon: Coupon, days: number): boolean {
  if (!coupon?.expirationDate) return false;
  const today = dayjs();
  const expirationDate = dayjs(coupon.expirationDate);
  return expirationDate.isAfter(today) && expirationDate.isBefore(today.add(days, 'days'));
}

function getCouponLogoUrl(coupon: Coupon): string {
  if (!coupon) return;
  const logo = getCouponLogo(coupon);
  if (logo) return `url(${logo})`;
}

function getPrimaryTextSize(text: string): string {
  if (text?.length > 5) return '16px';
  if (text?.length === 5) return '20px';
  if (text?.length === 4) return '24px';
  return '32px';
}

function getCouponPrimaryTextShadowColor(coupon: Coupon, theme: string): string {
  if (!coupon) return;
  if (theme === 'light') return getCouponPrimaryTextShadowColorLight(coupon);
  return getCouponPrimaryTextShadowColorDark(coupon);
}

export {
  isCouponKnown,
  isCouponLoyalty,
  isCouponDiscount,
  isCouponDummy,
  getCouponDiscount,
  discountMeetsCouponRequirements,
  getDiscountWarningMessage,
  getDiscountResultMessage,
  isCouponUsed,
  isCouponExpired,
  isCouponDisabled,
  isCouponValid,
  isCouponPersonalised,
  isCouponSelectable,
  isOrderCoupon,
  isCouponRedeemamble,
  getCouponBenefitText,
  getPizzaFanCouponDescription,
  isCouponRelatedToSynergy,
  getSpecificPromoCampaignCoupons,
  getBestCouponByPriority,
  isCouponRelatedToOverriddenSynergy,
  couponSpecificPromoOptions,
  getCouponsSynergies,
  couponExpiresWithinDaysRange,
  getCouponLogoUrl,
  getPrimaryTextSize,
  getCouponPrimaryTextShadowColor,
  filterWalletCoupons,
  couponHasDefaultShopFiltering
};
