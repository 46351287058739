import { Injectable } from '@angular/core';
import { CouponsService, SentryService } from '@box-core/services';
import { APIError, Coupon } from '@box-types';
import { sortUnavailableCoupons } from '@box/utils';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable()
export class RewardsUnavailableCouponsDialogService {
  constructor(private couponsService: CouponsService, private sentryService: SentryService) {}

  public fetchCoupons(): Observable<Coupon[]> {
    return this.couponsService.fetchUnavailableCoupons$().pipe(
      map((coupons) => sortUnavailableCoupons(coupons)),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Coupons',
          domainDetails: 'Rewards Unavailable Coupons',
          severity: 'warning'
        });
        return of([]);
      })
    );
  }
}
